.badge {
  // --------------------------------
  //
  // Pills
  //
  // --------------------------------
  &-pill {
    border-radius: 25px;
    padding: 4px 8px;
    font-size: 0.75rem;
  }

  &-primary {
    background-color: var(--color-primary__regular);
    color: var(--color-primary__light);
  }

  &-success {
    background-color: var(--color-success__light);
    color: var(--color-success__regular);
  }

  &-danger {
    background-color: var(--color-error__light);
    color: var(--color-error__regular);
  }

  &-info {
    background-color: var(--color-neutral__accent-background);
    color: var(--color-neutral__title-active);
  }
}
