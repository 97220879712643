.modal {
  .control-box {
    border-radius: 4px;
    padding: 20px 30px;
    background-color: var(--color-neutral__card-background);
    border: 1px solid var(--color-neutral__line);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    &:hover {
      background-color: var(--color-neutral__accent-background);
      color: var(--color-neutral__body);
    }
    &.selected {
      background-color: var(--color-primary__light);
      color: black;
    }
  }
}
