.modal {
  .modal-body {
    .locations-list {
      .customer-name {
        background-color: var(--color-neutral__accent-background);
        color: var(--color-neutral__label);
        position: -webkit-sticky; // for safari
        position: sticky;
        width: 100%;
        top: -16px;
      }

      .customer-locations-group {
        margin-left: 4px;
        padding-left: 16px;
        border-left: 1px solid var(--color-secondary__light);

        .fulfillment-method {
          color: var(--color-neutral__label);
        }

        .fulfillment-method-locations-group {
          padding-left: 16px;
          padding-top: 10px;
          margin-left: 4px;
          margin-bottom: 20px;
          border-left: 1px solid var(--color-neutral__line);

          &:hover {
            border-left-color: var(--color-primary__regular);
          }
        }
      }
    }
  }
}
