.card {
  --card-border__width: 0.5px;

  // Theming
  border-width: var(--card-border__width);
  border-color: var(--color-neutral__line);
  background-color: var(--color-neutral__card-background);

  border-style: solid;
  overflow: hidden;
  width: 100%;
  height: 100%;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  &-header {
    // Theming
    border-bottom-width: var(--card-border__width);
    border-bottom-style: solid;
    border-bottom-color: var(--color-neutral__line);
    background-color: var(--color-neutral__card-background);

    display: flex;
    flex-wrap: wrap;
  }

  &-body {
    // Theming

    table {
      width: 100%;
    }
  }

  &-footer {
    // Theming
    border-top-width: var(--card-border__width);
    border-color: var(--color-neutral__line);
    background-color: var(--color-neutral__accent-background);

    display: flex;
    justify-content: flex-end;
  }
}
