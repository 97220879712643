.toast-modal {
  .restaurant {
    cursor: pointer;
    padding: 8px 12px;
    border: 1px solid var(--color-neutral__accent-background);
    background-color: var(--color-neutral__accent-background);
    border-radius: 4px;
    color: var(--color-neutral__placeholder);

    &:hover {
      background-color: var(--color-primary__light);
    }
  }

  .loading-spinner-container {
    text-align: center;
    margin-right: 40px;
    .spinner-border {
      position: absolute;
      z-index: 1;
      margin-top: 180px;
    }
  }
}
