.btn {
  // Theming
  --btn-border__width: 1px;
  --btn-border__radius: 4px;

  @include roboto(1rem, 500, normal, 1.5rem);
  outline: 0px;
  border-width: var(--btn-border__width);
  border-style: solid;
  border-radius: var(--btn-border__radius);

  &:focus {
    outline: 0px;
    box-shadow: none;
  }

  // -----------------------------------------------------
  // Sizes
  // -----------------------------------------------------
  &-sm {
    padding: 8px 16px;
    @include roboto(12px, 500, normal, 12px);
    font-weight: 500;
  }

  // -----------------------------------------------------
  // Types
  // -----------------------------------------------------
  &-primary {
    background-color: var(--color-primary__regular);
    border-color: var(--color-primary__regular);
    color: var(--color-primary__light);

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):hover {
      background-color: var(--color-primary__dark);
      border-color: var(--color-primary__dark);
      color: var(--color-primary__light);
    }
  }

  &-link {
    color: var(--color-primary__regular);
    padding: 0;
    text-align: left;
  }

  &-light {
    color: var(--color-neutral__body);
    background-color: var(--color-neutral__accent-background);
    border-color: var(--color-neutral__accent-background);

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):hover {
      color: var(--color-neutral__body);
      border-color: var(--color-neutral__line);
      background-color: var(--color-neutral__line);
    }
  }

  &-outline {
    &-primary {
      border-color: var(--color-primary__regular);
      color: var(--color-primary__regular);

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled):hover {
        background-color: var(--color-primary__dark);
        border-color: var(--color-primary__dark);
        color: var(--color-primary__light);
      }
    }

    &-secondary {
      border-color: var(--color-neutral__line);
      color: var(--color-secondary__regular);

      &:not(:disabled):not(.disabled):focus {
        background-color: var(--color-secondary__regular);
        border-color: var(--color-secondary__regular);
        color: var(--color-secondary__light);
      }

      //&:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled):hover {
        background-color: var(--color-secondary__light);
        border-color: var(--color-secondary__light);
        color: var(--color-secondary__dark);
      }
    }

    &-danger {
      background-color: var(--color-error__light);
      border-color: var(--color-error__regular);
      color: var(--color-error__regular);

      &:not(:disabled):not(.disabled):focus {
        background-color: var(--color-error__regular);
        border-color: var(--color-error__regular);
        color: var(--color-error__light);
      }

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled):hover {
        background-color: var(--color-error__regular);
        border-color: var(--color-error__regular);
        color: var(--color-error__light);
      }
    }
  }
}
