// --------------------------------
//
// Positioning
//
// --------------------------------

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;

  &.top-10 {
    top: 10px;
  }
}

// --------------------------------
//
// Borders
//
// --------------------------------

.border-bottom-neutral {
  border-bottom: 1px solid var(--color-neutral__line);
}

// --------------------------------
//
// Width
//
// --------------------------------

.full-width {
  width: 100%;
}

// --------------------------------
//
// Flex
//
// --------------------------------

.d-flex {
  display: flex;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.center {
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
}

// --------------------------------
//
// Dividers & <hr/>
//
// --------------------------------

hr {
  &.bg-grey-12 {
    background-color: var(--color-neutral__line);
  }
}

// --------------------------------
//
// Breadcrumbs
//
// --------------------------------

.breadcrumbs {
  background-color: var(--color-primary__light);
  padding: 10px;
  margin: 8px;
  border-radius: 10px;
}
.breadcrumb-back {
  margin: 4px;
  color: var(--color-primary__regular);
  font-weight: normal;
}
.breadcrumb-link {
  margin: 4px;
  color: var(--color-primary__regular);
  font-weight: normal;
}

.hidden {
  display: none !important;
}

// --------------------------------
//
// Hidden Breakpoints
//
// --------------------------------

.hidden-desktop-sm {
  @include media("<desktop-sm") {
    display: none !important;
  }
}

// ---------------------------------
//
// Skeleton Divs
//
// ---------------------------------
.skeleton-div {
  height: 25px;
  background: linear-gradient(90deg, rgba(lightgrey, 0) 0, rgba(lightgrey, 0.8) 50%, rgba(lightgrey, 0) 100%);
  background-size: 400% 400%;
  animation: loading 8s infinite;

  &__lg {
    height: 34px;
  }

  &__sm {
    height: 20px;
  }
}

@keyframes loading {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
