@use "../../../../styles/global/abstracts/include-media";

.create-new-fulfillment-notice-modal {
  position: relative;

  .close {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .modal-content {
    border: 2px solid var(--color-neutral__line);

    p:not(.supporting-text) {
      font-weight: 400;
      font-size: 1.25rem;
      color: var(--color-neutral__body);
      margin-bottom: 10px;
    }

    .modal-header {
      border-bottom: none;
    }

    .modal-body {
      .station-location-card {
        width: 100%;
        border: 2px solid var(--color-neutral__line);
        border-radius: 4px;
        color: var(--color-neutral__body);
        margin: 0px;
        cursor: pointer;
        position: relative;

        input {
          display: none;
        }

        &.selected {
          border: 2px solid var(--color-primary__regular);
          color: var(--color-primary__regular);
          background: linear-gradient(45deg, var(--color-neutral__line) 0%, var(--color-primary__light) 100%);

          &:after {
            content: url("/assets/svgs/check-icons/icon-blue-solid-check.svg");
            position: absolute;
            top: 10px;
            right: 10px;
            height: 20px;
            width: 20px;
            border-radius: 10px;
          }
        }
      }
    }

    .modal-footer {
      border-top: none;
      padding-left: 30px;
      padding-right: 30px;

      &-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }

      .actions {
        display: flex;
      }

      > * {
        margin: 0px;
      }
    }
  }
}
