.fulfillment-notice-card {
  .card-body {
    padding-left: 0px;
    padding-right: 0px;
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    font-weight: bold;
  }

  &__table {
    width: 100%;
    font-weight: normal;
    border: none;

    tr {
      td,
      th {
        &:first-child {
          padding-left: 15px;
        }

        &:last-child {
          padding-right: 15px;
        }
      }
    }

    th {
      color: var(--color-neutral__title-active);
      font-weight: normal;
      font-size: 0.825rem;
    }

    tbody {
      tr:hover {
        background-color: var(--color-primary__light);
        cursor: pointer;
      }

      td {
        font-weight: 600;
        padding: 15px 0px;
      }

      .status-col {
        width: 50%;
        text-align: left;
        border: None;
      }

      .minus-sign {
        margin-left: 10px;
      }
    }
  }

  .green-circle {
    display: block;
    height: 10px;
    width: 10px;
    margin-left: 10px;
    border-radius: 50%;
    background-color: var(--color-success__regular);
  }
}
