.scheduler {
  max-height: 700px;
  overflow-y: auto;

  table {
    width: 100%;
    border-spacing: 0;
    table-layout: fixed;
    border-collapse: separate;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    thead {
      tr {
        th {
          position: -webkit-sticky;
          position: sticky;
          top: 0;
          background-color: var(--color-neutral__card-background);

          text-align: center;
          border: 1px solid #ececec;

          color: var(--color-neutral__icon);
          font-weight: normal;

          z-index: 1;

          textarea {
            font-size: 0.8rem;
            min-height: 30px;
            width: 100%;
            border: none;
            background-color: transparent;
            padding: 0 6px;
            color: var(--color-neutral__body);
          }

          &.text-schedule-input {
            padding: 0;
          }

          &.no-border {
            border: none;
          }
        }
      }
    }

    tbody {
      max-height: 600px;
      overflow-y: auto;

      tr {
        max-height: 16px !important;
        &.text-schedule-input {
          font-weight: normal;
        }

        td {
          height: 25px;
          padding: 0 0 6px 10px;
          color: var(--color-neutral__placeholder);
          //border: 1px solid var(--color-neutral__line);
          border: 1px solid #ececec;

          &:not(:first-child):hover {
            background-color: var(--color-primary__light);
            cursor: pointer;
          }

          &.selected {
            background-color: var(--color-primary__regular);
          }

          &.time-label {
            font-size: 0.8rem;
            border: none;
            text-align: right;
            padding-right: 20px;
          }
        }
      }
    }
  }
}

.show-week-view-btn {
  position: relative;
  left: 45%;
  bottom: 0;
  height: 25px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}
