.icon {
  &.xx-small {
    width: 15px;
    height: 15px;
  }

  &.x-small {
    width: 20px;
    height: 20px;
  }

  &.small {
    width: 30px;
    height: 30px;
  }

  &.medium {
    width: 40px;
    height: 40px;
  }

  &.full-width {
    width: 94%;
    height: auto;
  }
}

.clickable-icon {
  cursor: pointer;
}

.anticon {
  color: #667085;
}
