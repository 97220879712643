.confirmation-modal {
  .changes-list {
    .change-entry {
      .change-container {
        color: var(--color-neutral__label);
        display: flex;
        justify-content: space-between;
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--color-neutral__accent-background);
        margin-bottom: 10px;
      }
    }
  }
}
