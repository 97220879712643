@import "../../../../styles/global/abstracts/include-media";

textarea:focus,
input:focus {
  outline: none;
}

.toast-actions-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.fulfillment-notices-modal {
  .modal-header {
    border-bottom: none;
    padding-left: 30px;
  }

  .modal-banner {
    background-color: var(--color-neutral__accent-background);
    padding: 30px;
    .available-variables {
      font-size: 16px;
    }
    .variable-name {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .modal-body {
    @include media(">phone") {
      padding-right: 30px;
      padding-left: 30px;
    }

    .modal-label {
      color: var(--color-neutral__label);
      font-size: smaller;
    }
    .modal-input {
      width: 100%;
      border: None;
      border-bottom: 1px solid var(--color-neutral__line);
    }

    .helper-text {
      font-size: 1rem;
      color: var(--color-neutral__body);
    }

    .modal-message-default-container {
      background-color: var(--color-neutral__card-background);
      color: var(--color-neutral__body);
      border-right: 2px;
      padding: 16px;
    }
  }
}
