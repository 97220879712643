// Fonts
html {
  font-size: 16px;
  color: var(--color-neutral__body);

  @include media("<=phone-xs") {
    font-size: 14px;
  }
}

// Roboto Thin 100
@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto/Roboto-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

// Roboto Thin 100 Italic
@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto/Roboto-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

// Roboto Light 300
@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

// Roboto Light 300 Italic
@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto/Roboto-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

// Roboto Regular 400
@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

// // Roboto Regular 400 Italic
// @font-face {
//   font-family: "Roboto";
//   src: url("/assets/fonts/Roboto/Roboto-RegularItalic.ttf") format("truetype");
//   font-weight: 400;
//   font-style: italic;
//   font-display: swap;
// }

// Roboto Medium 500
@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

// Roboto Medium 500 Italic
@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto/Roboto-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

// Roboto Bold 700
@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

// Roboto Bold 700 Italic
@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto/Roboto-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

// Roboto Black 900
@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto/Roboto-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

// Roboto Black 900 Italic
@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto/Roboto-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

// Roboto with options
@mixin roboto($font-size: 1rem, $font-weight: 400, $font-style: normal, $line-height: 1) {
  font-family: "Roboto", sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  font-style: $font-style;
  line-height: $line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block-start: 0em;
  margin-block-end: 0em;
}

// Header typography
h1 {
  color: var(--color-neutral__title-active);
  @include roboto(3.5rem, 600, normal, 4.25rem);

  @include media("<=phone") {
    @include roboto(2rem, 600, normal, 2.9rem);
  }
}

h2 {
  color: var(--color-neutral__title-active);
  @include roboto(2rem, bold, normal, 2.5rem);
}

h3 {
  color: var(--color-neutral__title-active);
  @include roboto(1.5rem, 400, normal, 2.125rem);
}

// Dont change color here, this is only concerns size
h3 + p {
  @include roboto(1.125rem, 300, normal, 1.5);
}

h4 {
  color: var(--color-neutral__title-active);
  @include roboto(1.375rem, 500, normal, 1.6);
}

h5 {
  color: var(--color-neutral__title-active);
  @include roboto(0.938rem, 600, normal, auto);
}

a {
  @include roboto(1rem, bold, inherit, 1.25);
  color: var(--color-primary__regular);
  text-decoration: none;
}

p {
  color: var(--color-neutral__body);
  @include roboto(1rem, 300, inherit, 1.53);

  > a {
    @include roboto(inherit, inherit, inherit, inherit);
    color: var(--color-primary__regular);
    text-decoration: none;
  }
}

li {
  color: var(--color-neutral__body);
  @include roboto(1rem, 300, inherit, 1.53);
}

.text-small-detail {
  @include roboto(0.75rem, inherit, inherit, 16px);
  color: var(--color-neutral__label);
}

.text {
  &-x-small {
    @include roboto(0.875rem, 300, 0.01em, 1.38);
  }
}

.supporting-text {
  @include roboto(0.875rem !important, 300, 0.01em, 1.38);
}

b {
  @include roboto(inherit, bold, inherit, 1.4);
}

i {
  @include roboto(inherit, inherit, italic, 1.4);
}

q {
  @include roboto(1.625rem, 300, 0.01em, 1.54);
}

label {
  color: var(--color-neutral__label);
}

.center {
  text-align: center;
}

.weight-300 {
  font-weight: 300;
}

.weight-400 {
  font-weight: 400;
}

.weight-500 {
  font-weight: 500;
}

.weight-600 {
  font-weight: 600 !important;
}

.underline {
  text-decoration: underline;
}

.uppercase {
  text-transform: uppercase;
}

.letter-spacing-2 {
  letter-spacing: 2px;
}

.cursor-pointer {
  cursor: pointer;
}
