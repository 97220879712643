.alert {
  // Theming
  --btn-border__width: 1px;
  --btn-border__radius: 4px;

  padding: 10px;

  @include roboto(1rem, 500, normal, 1.5rem);
  outline: 0px;
  border-width: var(--btn-border__width);
  border-style: solid;
  border-radius: var(--btn-border__radius);

  // -----------------------------------------------------
  // Types
  // -----------------------------------------------------
  &-primary {
    background-color: var(--color-primary__light);
    border-color: var(--color-primary__light);
    color: var(--color-primary__dark);

    .btn-link,
    .alert-link {
      color: var(--color-primary__dark);
    }
  }

  &-secondary {
    opacity: 0.9;
  }

  &-light {
    color: var(--color-neutral__body);
    background-color: var(--color-neutral__accent-background);
    border-color: var(--color-neutral__accent-background);
  }

  &-danger {
    background-color: var(--color-error__light);
    border-color: var(--color-error__regular);
    color: var(--color-error__regular);
  }

  &-heading {
    font-size: 1.2rem;
  }
}
