.bbot-scroll-container {
  &::-webkit-scrollbar {
    background: none;
    width: 32px;
  }
  &::-webkit-scrollbar-track {
    border: 14px solid transparent;
    border-bottom: 16px solid transparent;
    border-top: 16px solid transparent;
    box-shadow: inset 0 0 32px 32px #cfcfcf99;
  }
  &::-webkit-scrollbar-thumb {
    border: 14px solid transparent;
    border-bottom: 16px solid transparent;
    border-top: 16px solid transparent;
    box-shadow: inset 0 0 32px 32px #6f6f6f;
    &:hover {
      box-shadow: inset 0 0 32px 32px var(--color-neutral__title-active);
    }
  }

  &__dark-background {
    &::-webkit-scrollbar {
      background: none;
      width: 18px;
    }
    &::-webkit-scrollbar-thumb {
      border: 7px solid transparent;
      border-bottom: 16px solid transparent;
      border-top: 16px solid transparent;
      box-shadow: inset 0 0 32px 32px #6f6f6f;
    }
  }
}
