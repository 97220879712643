@import "../global/abstracts/include-media";

.login-title {
  text-align: center;
}

.new-customer-form {
  width: 60%;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}

.new-customer-submit {
  margin-top: 12px;
  padding: 16px;
  width: 100%;
  font-size: 1.3em;
  border-radius: 4px;
  border: none;
}
.new-customer-register-btn {
  margin-top: 12px;
  padding: 16px;
  width: 100%;
  font-size: 1.3em;
  border-radius: 4px;
}

.form-error {
  color: red;
}
.new-customer-error {
  text-align: left;
  color: red;
  margin: 12px 0;
}

.background-image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  object-fit: cover;
}

.login-page-container {
  width: 100vw;
  min-height: 100vh;
  position: relative;
  background-color: var(--color-primary__regular);

  > .container-fluid > .row > div {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include media("<desktop-sm") {
      min-height: 0px;
    }
  }

  .login-container {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding-top: 80px;
    padding-right: 40px;
    padding-left: 40px;
    height: calc(100vh - 100px);
    overflow-y: scroll;
    background-color: var(--color-neutral__card-background);
    bottom: 0px;
    max-width: 500px;
    align-self: center;
    display: flex;
    flex-direction: column;

    input {
      width: 100%;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    @include media("<desktop-sm") {
      height: 100vh;
      max-width: none;
      width: 100vw;
      border-radius: 0px;
      margin-left: -15px;
      margin-right: -15px;
    }

    > div {
      flex: 0 0 auto;
    }

    .content {
      flex: 1 0 auto;
    }
  }

  .marketing-carousel {
    &.carousel,
    .carousel-inner,
    .carousel-item {
      position: relative;
      height: 100%;
      width: 100%;

      h3,
      p {
        color: white;
      }

      img {
        position: relative;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .gradient-overlay {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0.5;
  }
}

.links-hr {
  border-top: 1px solid var(--color-neutral__line);
}

.register-form-container {
  padding: 20px 40px;
}
