@use "../../../../styles/global/abstracts/include-media";

.create-new-fulfillment-notice-modal {
  position: relative;

  .close {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .modal-content {
    border: 2px solid var(--color-neutral__line);

    p:not(.supporting-text) {
      font-weight: 400;
      font-size: 1.25rem;
      color: var(--color-neutral__body);
      margin-bottom: 10px;
    }

    .modal-header {
      border-bottom: none;
    }

    .modal-body {
      .notification-container {
        background-color: var(--color-neutral__accent-background);
        padding: 30px;
        color: var(--color-neutral__body);
      }
    }

    .modal-footer {
      border-top: none;
      padding-left: 30px;
      padding-right: 30px;

      &-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }

      .actions {
        display: flex;
      }

      > * {
        margin: 0px;
      }
    }
  }
}
