.accordion {
  .card {
    border: 1px solid var(--color-neutral__line);
    &:not(:last-of-type) {
      border-bottom: 1px solid var(--color-neutral__line);
    }
    background-color: white;
    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--color-neutral__accent-background);

      .right-actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
    .card-body {
      border-bottom: 1px solid var(--color-neutral__line);
    }
  }
}
