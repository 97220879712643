.form-descriptor {
  font-style: italic;
  font-size: 0.95rem;
  margin-bottom: 35px;
}

.connect-stripe-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .stripe-icon {
    max-width: 200px;
  }
}

.bills-list,
.subscriptions-list {
  .bill,
  .subscription {
    &:not(:last-child) {
      border-bottom: 1px solid var(--color-neutral__accent-background);
    }
  }
}
