$journey-nav-height: 72px;

.journey-container {
  height: calc(100vh - #{$journey-nav-height});
}
.journey-nav {
  background-color: var(--color-neutral__accent-background);
  border-bottom: 2px solid var(--color-neutral__line);
  height: $journey-nav-height;
}
.journey-row {
  height: 100%;
}

.nav-link {
  color: black;
  font-weight: lighter;
}

.task-list-column {
  height: 100%;
  overflow-y: auto;
  padding: 0;
  border-right: 1px solid var(--color-neutral__line);
}

.task-column {
  height: 100%;
  overflow-y: auto;
}

// Task card
.taskcard {
  border-bottom: 1px solid var(--color-neutral__line);
  background-color: var(--color-neutral__card-background);
  padding: 8px 40px;
  height: 70px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .taskcard-title {
    font-size: 1.2em;
  }

  .skeleton {
    width: 80%;
  }
}

.taskcard.not-completed {
  background-color: #cdcdcd;
}

.selected {
  background-color: var(--color-primary__light);
}

.taskcard:hover {
  background-color: #e0e0f1;
}

.task {
  .task-info {
    .skeleton-title {
      width: 25%;
    }
  }
}

// This is duplicated from Base components
.badge {
  padding: 8px 16px;
  border-radius: 20px;

  &-pill {
    border-radius: 25px;
    padding: 4px 8px;
    font-size: 0.75rem;
  }

  &-primary {
    background-color: var(--color-primary__regular);
    color: var(--color-primary__light);
  }

  &-success {
    color: var(--color-success__regular);
    background-color: var(--color-success__light);
  }

  &-warning {
    background-color: var(--color-warning__light);
    color: var(--color-warning__dark);
  }

  &-danger {
    color: var(--color-error__regular);
    background-color: var(--color-error__light);
  }

  &-info {
    background-color: var(--color-neutral__accent-background);
    color: var(--color-neutral__title-active);
  }
}
.taskcard-status {
  border-radius: 12px;
  padding: 4px 8px;
  width: 120px;
  text-align: center;
  font-size: 0.75em;
}

.task-status {
  padding: 4px 8px;

  border-radius: 4px;
}

.task-status.in_progress {
  background-color: #a0e4ff;
}

.task-status.blocked {
  background-color: #ffb6b6;
}

.task-status.completed {
  background-color: #beffbe;
}

.check-icon {
  margin: 8px;
  width: 100%;
  height: auto;
  color: green;
}

// ==================== JOURNEY CHOOSER ============================
.add-journey-btn {
  border-radius: 25px;
}
.journeys-container {
  width: 60vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.journeys-table {
  width: 100%;
  border: 1px solid var(--color-neutral__line);
}
.journey-chooser-row {
  &:hover {
    cursor: pointer;
    background: var(--color-primary__light);
  }
  border-bottom: 1px solid var(--color-neutral__line);
}
.journey-chooser-cell {
  padding: 4px 8px;
}
.journey-chooser-name-blocked {
  background-color: red;
  margin: 0 8px;
  color: white;
  border-radius: 12px;
  padding: 4px 8px;
}
.journey-chooser-modal-body {
  display: flex;
  justify-content: space-between;

  span {
    width: 50%;
  }
}
.loading-icon {
  max-height: 100px;
  margin-right: auto;
  margin-left: auto;
  display: block;
}

.journey-completed-modal {
  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      max-height: 100px;
      max-width: 100px;
    }

    .onboarding-complete-description {
      text-align: center;
    }
  }
}
