@mixin theme-light-css-variables {
  // --------------------------------------
  //
  // Theme Identifier
  //
  // --------------------------------------
  --theme__id: "light";

  // --------------------------------------
  //
  // Colors
  //
  // --------------------------------------

  // Primary
  --color-primary__regular: #1167e8;
  --color-primary__light: #eaf2fe;
  --color-primary__dark: #034284;

  // Secondary
  --color-secondary__regular: #20409a;
  --color-secondary__light: #7494ef;
  --color-secondary__dark: #00112a;

  // Success
  --color-success__regular: #18981d;
  --color-success__light: #e3f9e5;
  --color-success__dark: #05400a;

  // Warning
  --color-warning__regular: #f7c948;
  --color-warning__light: #fcf1cf;
  --color-warning__dark: #4e3b09;

  // Error
  --color-error__regular: #e12d39;
  --color-error__light: #ffeeee;
  --color-error__dark: #490311;

  // Neutral
  --color-neutral__title-active: #0f172a; // 900
  --color-neutral__body: #1e293b; // 800
  --color-neutral__label: #475569; // 600
  --color-neutral__icon: #64748b; // 500
  --color-neutral__placeholder: #94a3b8; // 400
  --color-neutral__line: #cbd5e1; // 300
  --color-neutral__accent-background: #f1f4f8; // 200
  --color-neutral__page-background: #f8fafc; // 100
  --color-neutral__antd-page-background: #ffffff; // 100
  --color-neutral__card-background: #ffffff; // 50
  --color-neutral__modal-background: #ffffff; // 50
  --color-neutral__input-background: #ffffff; // 50

  // Button Specific
  --color-button-default__background: #f5f8f9;

  // --------------------------------------
  //
  // Borders
  //
  // --------------------------------------

  // Border
  --border-width__regular: 1px;
  --border-width__thin: 1px;
  --border-width__thick: 3px;
  --border-width__card: 0.5px;
  --border-width__input: 1px;
  --border-radius__regular: 4px;
  --border-radius__small: 4px;
  --border-radius__large: 6px;
}
