@import "../../styles/global/abstracts/include-media";

.dashboard-link {
  :hover {
    background-color: var(--color-neutral__accent-background);
  }

  a {
    color: var(--color-neutral__label);
    &.super-admin-link {
      color: var(--color-error__regular);
    }

    .dashboard-link-container {
      padding: 10px;
      border-radius: 8px;

      font-weight: normal;
      font-size: 1rem;

      display: flex;
      align-content: center;

      .material-icons-outlined {
        font-size: 1.125rem;
        margin-right: 1rem;
      }
    }
  }
}

.menu-column {
  margin-bottom: 50px;
  padding: 0px 40px;

  .heading-container {
    display: flex;
    .dashboard-link-icon {
      font-size: 32px;
      text-align: right;
      color: var(--color-neutral__icon);
      margin-right: 16px;
    }

    .dashboard-menu-title {
      font-size: 1.4rem;
    }
  }
}
