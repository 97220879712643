.styled-form,
form {
  // Input Defaults
  --input__border-radius: 8px;
  --input__border-width: 1px;
  --input__border-style: solid;
  --input__border-color: var(--color-neutral__line);
  --input__background-color: var(--color-neutral__input-background);

  // States
  // Focused Theming
  --input-focused__border: 1px solid var(--color-primary__light);
  --input-focused__box-shadow: 0 0 0 0.2rem var(--color-error__light);

  // Success Theming
  --input-success__border: 1px solid var(--color-success__regular);
  --input-success__text-color: var(--color-success__regular);
  --input-success__background-color: var(--color-success__light);

  // Error Theming
  --input-error__border: 1px solid var(--color-error__regular);
  --input-error__text-color: var(--color-error__regular);
  --input-error__background-color: var(--color-error__light);
  --input-error__box-shadow: 0 0 0 0.2rem var(--color-error__light);

  .form-label {
    width: 100%;
    margin-bottom: 10px;
  }

  .form-control {
    &.is-invalid {
      color: var(--color-error__regular);
      border: var(--input-error__border);

      &:focus {
        box-shadow: var(--input-error__box-shadow);
      }
    }
  }

  input[type="email"],
  input[type="text"],
  input[type="number"],
  input[type="password"],
  input[type="tel"],
  select {
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;
    height: auto;
    background-color: var(--input-initial__background-color);
    border-radius: var(--input__border-radius);
    border-width: var(--input__border-width);
    border-style: var(--input__border-style);
    border-color: var(--input__border-color);

    &::placeholder {
      color: var(--color-neutral__placeholder);
      font-weight: 400;
    }

    &:focus {
      outline: none;
      border: var(--input-focused__border);
    }
  }

  /**
  ------------------------------------------------------------------------------
  React Select
  ------------------------------------------------------------------------------
  <div class="react-select-custom-styling">
    <div class="react-select__control">
      <div class="react-select__value-container">...</div>
      <div class="react-select__indicators">...</div>
    </div>
    <div class="react-select__menu">
      <div class="react-select__menu-list">
        <div class="react-select__option">...</div>
      </div>
    </div>
  </div>
   */
  .react-select-custom-styling {
    .react-select {
      &__control {
        background-color: var(--input-initial__background-color);
        border-radius: var(--input__border-radius);
        border-width: var(--input__border-width);
        border-style: var(--input__border-style);
        border-color: var(--input__border-color);
      }
      &__placeholder {
        color: var(--color-neutral__placeholder);
      }
      &__indicator-separator {
        background-color: var(--input__border-color);
      }

      &__dropdown-indicator {
        svg {
          color: var(--input__border-color);
        }
      }
      &__value-container {
        padding-left: 16px;
        padding-top: 6px;
        padding-bottom: 4px;
      }

      &__menu {
        border-radius: var(--input__border-radius);
        border-width: var(--input__border-width);
        border-style: var(--input__border-style);
        border-color: var(--input__border-color);
      }
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .form-check {
    input[type="checkbox"] {
      height: 1.25rem;
      width: 1.25rem;
      border: var(--input-intial__border);
    }

    label {
      margin-left: 14px;
      float: none;
      margin-bottom: 0;
    }
  }

  select {
    color: var(--color-neutral__body);
  }

  input[type="checkbox"] {
    height: 1.25rem;
    width: 1.25rem;
    margin-right: 10px;
  }

  .description-col {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .form-fields-col {
    border-left-style: solid;
    border-left-color: var(--color-neutral__line);
    border-left-width: 1px;
    padding-left: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
