.sales-commission-card {
  table {
    th {
      color: var(--color-neutral__title-active);
      font-weight: normal;
      font-size: 0.825rem;
    }

    tbody {
      td {
        padding: 8px 0px;
      }
    }
  }
}
