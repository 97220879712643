.feature-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .feature-header {
    h3 {
      display: inline-block;
    }
    display: flex;
    align-items: center;
  }
}
