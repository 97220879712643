@import "theme-light";
@import "theme-dark";

// In case any browser doesn't support prefers-color-scheme just default to use light theme so they can be overridden
:root {
  @include theme-light-css-variables;
}

@media (prefers-color-scheme: light) {
  :root {
    @include theme-light-css-variables;
  }
}

// TODO: Switch this to use the theme-dark-css-variables when we add support for dark mode theme
@media (prefers-color-scheme: dark) {
  :root {
    @include theme-light-css-variables;
  }
}

:root[data-theme="theme-light"] {
  @include theme-light-css-variables;
}

// TODO: Switch this to use the theme-dark-css-variables when we add support for dark mode theme
:root[data-theme="theme-dark"] {
  @include theme-light-css-variables;
}
