.duplicate-pins-alert {
  .alert-heading {
    .alert-heading-button {
      float: right;
    }
  }
}

.connect-employees-modal {
  .finished-icon-container {
    text-align: center;
    .finished-icon {
      max-height: 100px;
      max-width: 100px;
    }
  }
}

.edit-employee-modal {
  nav {
    a {
      font-weight: 400;
    }
  }
}

.employees-table-container {
  .employees-table-header {
    padding-top: 8px;
    .table-action-row {
      float: right;
    }
  }
  .employees-table {
    thead {
      tr {
        th {
          border: None;
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: 0.875rem;

          &.pin-cell {
            input {
              max-width: 3rem;
            }
          }

          input,
          select {
            font-size: 0.875rem;
          }

          .pin-input-group {
            .pin-input-control {
              padding: 4px 8px;
              height: auto;
              display: flex;
              justify-content: space-between;

              &.invalid {
                border-color: var(--color-error__regular);
              }

              border-color: var(--color-neutral__line);

              input {
                padding: 0;
                border: None;
                font-size: 0.875rem;
              }

              .icon-container {
                float: right;
              }
            }

            .pin-error {
              margin-top: 5px;
              color: var(--color-error__regular);
              font-size: 0.72rem;
            }
          }

          button {
            font-size: 0.875rem;
            width: 100%;
            padding-top: 0.3rem;
            padding-bottom: 0.3rem;
          }
        }
      }
    }
  }
}
