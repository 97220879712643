@use "sass:math";

// The range in available classes multiplying the sizee interval
// Size 1 = 1 * 10px = 10px
// Size 2 = 2 * 10px = 20px
// Size 3 = 3 * 10px = 30px
// Etc...
$sizes: 20;

// This is the multiplier for the difference between each padding and margin class, thus
// Size 1 = 1 * 10px = 10px
// Size 2 = 2 * 20px = 20px
$size-interval: 10px;

.align-items-center {
  align-items: center;
}

.full-width {
  width: 100%;
}

// Defined the margin classes
@mixin margin-classes {
  .margin-x-auto {
    margin-right: auto;
    margin-left: auto;
  }
  @for $i from 0 through $sizes {
    $margin: $i * $size-interval;
    /* margin #{$margin} */
    .margin-#{$i} {
      margin: $margin;
    }
    .margin-left-#{$i} {
      margin-left: $margin;
    }
    .margin-right-#{$i} {
      margin-right: $margin;
    }
    .margin-top-#{$i} {
      margin-top: $margin;
    }
    .margin-bottom-#{$i} {
      margin-bottom: $margin;
    }
    .margin-x-#{$i} {
      margin-left: $margin;
      margin-right: $margin;
    }
    .margin-y-#{$i} {
      margin-top: $margin;
      margin-bottom: $margin;
    }
  }
}
@include margin-classes;

@mixin padding-classes {
  @for $i from 0 through $sizes {
    $padding: $i * $size-interval;
    /* padding #{$padding} */
    .padding-#{$i} {
      padding: $padding;
    }
    .padding-low-#{$i} {
      padding-left: $padding;
    }
    .padding-right-#{$i} {
      padding-right: $padding;
    }
    .padding-top-#{$i} {
      padding-top: $padding;

      @if $i >= 10 {
        @include media("<=tablet") {
          padding-top: math.div($padding, 2);
        }
      }
    }
    .padding-bottom-#{$i} {
      padding-bottom: $padding;

      @if $i >= 10 {
        @include media("<=tablet") {
          padding-bottom: math.div($padding, 2);
        }
      }
    }
    .padding-x-#{$i} {
      padding-left: $padding;
      padding-right: $padding;
    }
    .padding-y-#{$i} {
      padding-top: $padding;
      padding-bottom: $padding;

      @if $i >= 10 {
        @include media("<=tablet") {
          padding-top: math.div($padding, 2);
          padding-bottom: math.div($padding, 2);
        }
      }
    }
  }
}
@include padding-classes;
