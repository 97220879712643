.ant-picker-panel-container {
  .ant-btn {
    border-radius: 5px;
    font-size: 14px;
    color: black;
    font-family: var(--default-font);
    background: white;
    border-color: lightgrey;

    &:active,
    &:hover,
    &:focus {
      color: black;
      border-color: lightgrey;
      background: var(--color-button-default__background);
      opacity: 0.9;
    }

    &-primary {
      color: white;
      background: var(--color-primary__regular);
      border-color: var(--color-primary__regular);

      &:active,
      &:hover,
      &:focus {
        color: white;
        background: var(--color-primary__dark);
        border-color: var(--color-primary__dark);
        opacity: 0.9;
      }
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff;
    background: var(--color-primary__regular);
    border-radius: 4px;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    border: 1px solid var(--color-primary__dark);
    border-radius: 4px;
    content: "";
  }

  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: var(--color-primary__light);
  }
}
