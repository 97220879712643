.statusButton {
  &.active {
    background-color: var(--color-primary__regular) !important;
    opacity: 1;
  }
  &:hover {
    opacity: 0.7;
  }
  opacity: 0.3;
}

.rotate90 {
  transform: rotate(90deg);
}

.header-search-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
