// ---------------------------------------------
//
// Text Color Classes
//
// ---------------------------------------------
.color-primary {
  &__regular {
    color: var(--color-primary__regular);
  }
  &__light {
    color: var(--color-primary__light);
  }
  &__dark {
    color: var(--color-primary__dark);
  }
}

.color-secondary {
  &__regular {
    color: var(--color-secondary__regular);
  }

  &__light {
    color: var(--color-secondary__light);
  }

  &__dark {
    color: var(--color-secondary__dark);
  }
}

.color-neutral {
  &__title-active {
    color: var(--color-neutral__title-active);
  }

  &__body {
    color: var(--color-neutral__body);
  }

  &__label {
    color: var(--color-neutral__label);
  }

  &__placeholder {
    color: var(--color-neutral__placeholder);
  }

  &__line {
    color: var(--color-neutral__line);
  }

  &__icon {
    color: var(--color-neutral__icon);
  }

  &__page-background {
    color: var(--color-neutral__card-background);
  }

  &__card-background {
    color: var(--color-neutral__card-background);
  }
}

.color-error {
  &__regular {
    color: var(--color-error__regular);
  }
  &__light {
    color: var(--color-error__light);
  }
  &__dark {
    color: var(--color-error__dark);
  }
}
