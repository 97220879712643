.footer-link {
  margin: 0 8px;
  font-size: 20px;

  p {
    color: var(--color-neutral__label);
  }
}

.footer-links {
  text-align: center;
  display: flex;
}
