.save-email-container {
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;

  button {
    width: 100%;
  }
}

.delete-icon {
  color: var(--color-error__dark);
  &:hover {
    cursor: pointer;
  }
}

/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

button.select-payment {
  padding: 0.4em 0.4em 0.4em;
  border-radius: 8px;
  background-color: #fff;
  font-size: 0.9em;
  border: 0;
  cursor: pointer;
  margin: 0.2em 0.5em 0 0;
  width: 40%;
  text-align: left;

  &:hover:not(.selected) {
    background-color: var(--color-neutral__placeholder);
    color: white;
  }
}

button.select-payment.selected {
  border: 2px solid var(--color-neutral__line);
  cursor: default;
}
.existing-payment-method,
.unverified-payment-source {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-neutral__line);
  padding: 4px;
  height: 40px;
  color: var(--color-neutral__body);
}
.select-primary-btn,
.action-btn {
  color: var(--color-primary__light);
}
.primary-label {
  color: var(--color-primary__dark);
}

.unverified-payment-source-name {
  display: inline-block;
  width: 50%;
}

.payment-method-status {
  padding: 8px 20px;
  border-radius: 25px;
  color: var(--color-neutral__body);

  &.unverified {
    background-color: var(--color-neutral__card-background);
  }
  &.primary {
    background-color: var(--color-primary__regular);
  }
}

.accordion {
  .card {
    //border-radius: 4px;
    border: 1px solid var(--color-neutral__line);
    background-color: white;

    &:not(:last-of-type) {
      border-bottom: 1px solid var(--color-neutral__line);
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:not(:first-of-type) {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }

    &.unverified {
      background-color: var(--color-neutral__accent-background);
    }

    &.primary {
      border: 1px solid var(--color-primary__regular);
    }

    .card-header {
      background-color: white;
      cursor: pointer;

      &.unverified {
        background-color: var(--color-neutral__accent-background);
      }

      .payment-name {
        font-size: 20px;
      }
    }

    .card-body {
      .payment-method-buttons {
        display: flex;
        justify-content: center;
      }
    }
  }
}
