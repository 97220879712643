.sales-person-table {
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    justify-content: space-between;

    .actions {
      display: flex;

      > * {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }

  .selection-button {
    display: flex;
    max-width: 40px;
    max-height: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid var(--color-neutral__line);
    margin-right: 0px;
  }

  table {
    font-size: 1rem;
    border-spacing: 0;
    border-collapse: separate;
    table-layout: auto;

    &.full-width {
      width: 100%;
    }

    thead {
      th {
        padding: 1rem 20px;

        &:not(:first-child) {
          border-left: 1px solid var(--color-neutral__line);
        }

        border-bottom: 1px solid var(--color-neutral__line);
      }
    }

    tbody {
      tr {
        cursor: pointer;

        &:nth-child(odd) {
          background-color: var(--color-neutral__accent-background);
        }

        &:hover {
          background-color: var(--color-neutral__accent-background);
        }

        td {
          border-bottom: 1px solid var(--color-neutral__line);
          padding: 1rem 20px;

          &:not(:first-child) {
            border-left: 1px solid var(--color-neutral__line);
          }
        }

        &:last-child {
          td {
            border-bottom: 1px dashed var(--color-neutral__line);
          }
        }

        &.selected {
          background-color: var(--color-primary__light);
        }
      }
    }
  }
}
