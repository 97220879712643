.share-link {
  display: inline-block;
  white-space: nowrap;
  height: 44px;
  width: 50%;
  padding-left: 8px;
  padding-right: 8px;
  background-color: white;
  border: 1px solid var(--color-neutral__placeholder);
  color: var(--color-neutral__body);
  resize: none;
  font-size: 0.875rem;

  &.in-modal {
    width: 23vw;
    margin: 0 8px;
  }
}

.copy-btn,
.copy-success {
  vertical-align: top;
  margin: 0 8px;
  padding: 8px;
  height: 44px;
}

.copy-btn:hover {
  background-color: var(--color-neutral__body);
  color: white;
}

.contact-info-form {
  width: 25vw;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}
.contact-info-label {
  margin-top: 12px;
}
.contact-info-input {
  width: 100%;
  font-size: 1.1em;
  border: 1px solid dimgrey;
  border-radius: 4px;
  padding: 6px;
}
.contact-info-error {
  color: red;
  height: 20px;
}

.upload-menu-method {
  margin: 8px;
}

.radio-label {
  margin: 8px;
}

.curation-upload-method {
  margin: 20px;
  padding: 20px 40px;
  border: 1px solid grey;
  border-radius: 4px;
}
.curation-upload-separator {
  text-align: center;
}
.submit-btn,
.filestack-picker-btn {
  margin: 12px 0;
  background-color: var(--color-primary__regular);
  border-radius: 4px;
  padding: 10px 30px;
  border: none;
  color: white;
}

#menu-link {
  width: 100%;
  border: 1px solid #bababa;
}

.menu-list-menu {
  padding: 8px;
  border: 1px solid grey;
  display: inline-block;
  width: 100%;
}

.delete-menu-btn {
  float: right;
  padding: 4px;
}

.menu-categories {
  color: grey;
}
.menu-items {
  margin: 0 12px;
  color: grey;
}
.curation-pending {
  background-color: #929292;
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
}
.add-menu-btn {
  margin: 12px 0;
}

.journey-chooser-journey {
  border: 1px solid var(--color-neutral__line);
  border-radius: 4px;
  margin: 12px;
  padding: 8px;
}
.customer-chooser {
  width: 50%;
  margin: 12px auto;
}
.journey-chooser-modal {
  text-align: center;
}

.inline-icon {
  margin: 0 8px;
}

.upload-success {
  border: 1px solid var(--color-success__regular);
  border-radius: 4px;
  background-color: var(--color-success__light);
  width: 40%;
  margin: 8px 0;
  padding: 4px;
}

.action-btns-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.tax-rate-widget {
  max-width: 40vw;
  margin-right: auto;
  margin-left: auto;

  #save-tax-rate-btn {
    width: 100%;
  }
}

.upload-logo-widget {
  .uploaded-logo-img {
    max-width: 30vw;
  }
}

//UPLOAD MENU WIDGET
.upload-menu-widget {
  .cards-row {
    .card-col {
      min-height: 160px;
      .card {
        height: 100%;
        border-color: var(--color-neutral__line);

        .card-header {
          background-color: white;
          border-color: var(--color-neutral__line);
        }

        .card-body {
          overflow-y: auto;
          max-height: 200px;
        }

        &#new-menu-button {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          text-align: center;

          &:hover {
            background-color: var(--color-neutral__line);
          }
        }

        .card-footer {
          background-color: white;
          display: flex;
          justify-content: flex-end;
          border-color: var(--color-neutral__line);
        }
      }
    }
  }
}

.modal {
  .menu-choice-card {
    width: 100%;
    height: 100%;
    padding: 16px;
    border: 2px solid var(--color-neutral__line);
    border-radius: 4px;
    color: var(--color-neutral__body);
    margin: 0px;
    cursor: pointer;
    position: relative;

    input {
      display: none;
    }

    &.selected {
      border: 2px solid var(--color-primary__regular);
      color: var(--color-primary__regular);

      &:after {
        content: url("/assets/svgs/check-icons/icon-blue-solid-check.svg");
        position: absolute;
        top: 10px;
        right: 10px;
        height: 20px;
        width: 20px;
        border-radius: 10px;
      }
    }
  }
}
