// react-toastify css override

.Toastify {
  &__toast {
    &--success {
      background-color: var(--color-success__light);
      color: var(--color-success__dark);
      padding: 16px 32px;
    }
    &--info {
      background-color: var(--color-neutral__card-background);
      color: var(--color-neutral__body);
    }
  }

  &__progress-bar {
    &--info {
      background: var(--color-neutral__line);
    }

    &--success {
      background: var(--color-success__regular);
    }
  }

  &__close-button {
    &--success {
      svg {
        fill: var(--color-success__dark);
      }
    }

    &--info {
      svg {
        fill: var(--color-neutral__title-active);
      }
    }
  }
}
