.table-container {
  background-color: var(--color-neutral__card-background);
  border: 1px solid var(--color-neutral__line);
  border-radius: 4px;
  box-shadow: 0 4px 4px rgba(178, 178, 178, 0.25);
  width: 100%;
  overflow: hidden;

  .table-scroll-container {
    overflow-x: auto;
  }

  .table-title {
    font-weight: bold;
    margin-bottom: 0;
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    justify-content: space-between;

    .actions {
      display: flex;

      > * {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }

  .selection-button {
    display: flex;
    max-width: 40px;
    max-height: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid var(--color-primary__regular);
    margin-right: 0px;
  }

  table {
    font-size: 1rem;
    border-spacing: 0;
    border-collapse: separate;
    table-layout: auto;
    margin-bottom: 0px;

    &.full-width {
      width: 100%;
    }

    thead {
      th {
        padding: 1rem 20px;
        white-space: nowrap;

        &.right-align {
          text-align: right;
        }

        &:not(:first-child) {
          border-left: 1px solid var(--color-neutral__line);
        }

        border-bottom: 1px solid var(--color-neutral__line);
      }
    }

    tbody {
      tr {
        cursor: pointer;

        &:nth-child(odd) {
          background-color: var(--color-neutral__accent-background);
        }

        &:hover {
          background-color: var(--color-primary__light);
        }

        td {
          border-bottom: 1px solid var(--color-neutral__line);
          padding: 1rem 20px;
          white-space: normal;

          a {
            color: var(--color-primary__regular);
          }

          &.right-align {
            text-align: right;
          }

          &:not(:first-child) {
            border-left: 1px solid var(--color-neutral__line);
          }
        }

        &:last-child {
          td {
            border-bottom: 1px dashed var(--color-neutral__line);
          }
        }

        &.selected {
          background-color: var(--color-primary__light);
        }
      }
    }
  }

  .add-item {
    border: none; // Removes ugly button border
    color: var(--color-neutral__label);
    background-color: var(--color-neutral__accent-background);
    border-top: 1px solid var(--color-neutral__line);

    outline: none;
    display: flex;
    width: 100%;
    padding: 1rem 20px;
    align-items: center;
    transition-timing-function: ease-in-out;
    transition-property: background-color;
    transition-duration: 0.2s;

    .icon-circle {
      font-size: 20px;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--color-primary__regular);
      background-color: var(--color-primary__light);
      border-radius: 50%;
      color: var(--color-primary__regular);
    }

    > span {
      margin-left: 20px;
    }

    &:hover {
      color: var(--color-primary__dark);
      background-color: var(--color-primary__light);

      .icon-circle {
        color: var(--color-primary__light);
        background-color: var(--color-primary__regular);
      }
    }

    &:focus {
      outline: none;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    min-height: 30px;
    padding: 20px;
  }
}

table {
  th {
    color: var(--color-neutral__title-active);
  }
}
