.divider {
  display: flex;
  flex-direction: row;
  color: grey;
  width: 100%;
}

.divider span {
  margin: auto;
}

.grouped-inputs {
  border: 1px solid rgba(169, 169, 169, 0.5);
  padding: 20px 20px 20px 20px;
}
