.activity-feed {
  .level-icon {
    height: 10px;
    width: 10px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
  }

  .level-info {
    color: var(--color-primary__regular);
  }

  .level-warn {
    color: var(--color-warning__regular);
  }

  .level-error {
    color: var(--color-error__regular);
  }

  .log-output {
    display: flex;
    min-height: 400px;
    overflow: hidden;
    border-top: 1px solid black;
    border-bottom: 1px solid black;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
      background-color: lightgrey;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    .log-entry {
      cursor: pointer;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid black;
      box-sizing: border-box;
      overflow: hidden;
      padding-right: 10px;
    }

    .log-level {
    }

    .log-details {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-content: center;
      overflow: hidden;
    }

    .log-summary {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      flex-direction: row;
      border-bottom: 1px solid lightgrey;

      .text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: bold;
      }

      &__category {
        flex: 1;
        font-size: 12px;
      }

      &__title {
        flex: 1;
        text-align: center;
      }

      &__time {
        flex: 1;
        font-size: 12px;
        text-align: right;
      }
    }

    .log-description {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 300;
    }

    .log:hover {
      background-color: lightblue;
    }
  }

  .actions {
    display: flex;
    padding-top: 1em;
    padding-bottom: 1em;

    .view-options-col {
      padding-right: 1em;

      .list-item {
        margin-left: 10px;
      }
    }
  }
}
