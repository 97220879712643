.modal {
  &-content {
    background-color: var(--color-neutral__modal-background);
    border: 0.5px solid var(--color-neutral__line);
    border-radius: 8px;
  }

  &-header {
    border-bottom: None;
    .close {
      span {
        font-weight: 300;
        font-size: 1.5rem;
      }
    }
  }

  &-body {
    &:not(.no-overflow) {
      max-height: 70vh;
      overflow-y: auto;
    }
  }

  &-banner {
    padding: 1rem;
  }

  &-footer {
    border-top: 0.5px solid var(--color-neutral__accent-background);

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;

    &.space-between {
      justify-content: space-between;
    }
  }
}
